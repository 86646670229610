import React, { useEffect } from "react";
import AOS from "aos";
import logo from "./assets/Asset 4@300x.png";
import {
  FaFacebookF,
  FaWhatsapp,
  FaSnapchatGhost,
  FaTiktok,
} from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
const App = () => {
  // handle animation
  useEffect(() => {
    AOS.init({
      offset: 0,
      duration: 1200,
    });
  }, []);
  return (
    <div className="mainDiv">
      <div className="overlay">
        <div className="d-flex flex-column align-items-center gap-3">
          <div
            data-aos="zoom-out"
            data-aos-delay="400"
            className="logoContainer"
          >
            <img loading="lazy" alt="logo/img" src={logo} className="logo" />
          </div>
          <p
            data-aos="fade-down"
            data-aos-delay="500"
            className=" m-0 p-0 fw-bolder title"
          >
            يقوم ابطالنا الان بتجهيز شيئا مميز سيغير سوق العقارات بالمملكة
          </p>
          <p className="m-0 p-0 fw-bolder text-white fs-5">كن طريقك الامن</p>
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            className="d-flex align-items-center gap-3 flex-wrap"
          >
            <div className="socialContainer">
              <a href="" target="_blank" className="m-0 p-0 text-white">
                <AiOutlineInstagram size={20} />
              </a>
            </div>
            <div className="socialContainer">
              <a href="" target="_blank" className="m-0 p-0 text-white">
                <FaFacebookF size={20} />
              </a>
            </div>
            <div className="socialContainer">
              <a href="" target="_blank" className="m-0 p-0 text-white">
                <FaWhatsapp size={20} />
              </a>
            </div>
            <div className="socialContainer">
              <a href="" target="_blank" className="m-0 p-0 text-white">
                <FaSnapchatGhost size={20} />
              </a>
            </div>
            <div className="socialContainer">
              <a href="" target="_blank" className="m-0 p-0 text-white">
                <FaTiktok size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
